import Image from "next/image"
import { Button } from "components/core"
import styles from "./HeroSection.module.css"

type Props = {
  openContactModal: () => void
}

export const HeroSection = ({ openContactModal }: Props) => {
  return (
    <div className={styles.container}>
      <div className="widecontainer">
        <div className={styles.leftHalf}>
          <h1>
            The Operating System for <span className={styles.highlight}>Public Company Equity</span>
          </h1>
          <p>
            One platform for employees, HR, Finance, and Legal. Integrates with your existing stock plan administrator.
          </p>
          <Button type="primary" size="large" title="Book a demo →" shape="square" onClick={openContactModal} />
        </div>
        <div className={styles.rightHalf}>
          <Image
            src="/images/home/hero-laptop.png"
            alt="The Operating System for Public Company Equity"
            width={851}
            height={549}
            priority={true}
          />
        </div>
      </div>
    </div>
  )
}
