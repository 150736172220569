import Image from "next/image"
import { useState } from "react"
import { toast } from "react-toastify"
import { Input, Button } from "components/core"
import analytics from "helpers/analytics"
import { showErrorMessageAndLogToSentry } from "helpers/frontendError"
import { useIsMobile } from "helpers/hooks/mediaQueryHook"
import styles from "./DemoSection.module.css"

const DemoSection = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [isSubmitted, setIsSubmitted] = useState(false)
  const isMobile = useIsMobile()

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const payload = {
        email,
        company,
      }
      const response = await fetch("/api/contact/for-issuers", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })

      const res = await response.json()
      if (res.success) {
        toast.success("Got it! A member of our team will get back to you shortly.")
        analytics.track("Issuer lead submitted", { user: { email: payload.email } })
        setIsSubmitted(true)
      } else {
        showErrorMessageAndLogToSentry({ message: "Something went wrong", details: { payload } })
      }
    } catch (err) {
      showErrorMessageAndLogToSentry({ message: "Something went wrong", details: { err } })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.container}>
      <div className="widecontainer">
        <Image
          src="/images/portfolios/fiduciary.jpg"
          alt="Book a demo"
          width={520}
          height={520}
          priority={true}
          className={styles.image}
        />
        <div className={styles.content}>
          <h1 className={styles.title}>Book a demo</h1>
          <div className={styles.subtitle}>See how Candor can work for your organization.</div>
          <div className={styles.form}>
            <div>
              <label>Your work email</label>
              <Input
                placeholder="you@example.com"
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </div>
            <div>
              <label>Your company</label>
              <Input placeholder="Acme Inc." type="text" value={company} onChange={(e) => setCompany(e.target.value)} />
            </div>
          </div>
          <Button
            shape="square"
            type="primary"
            title={isSubmitted ? "Request sent" : "Send request"}
            size="large"
            onClick={onSubmit}
            disabled={isSubmitted}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default DemoSection
