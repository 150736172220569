import Tooltip from "rc-tooltip"
import Link from "next/link"
import clsx from "clsx"
import CheckIcon from "vector/icons/check_icon.svg"
import SecurityIcon from "vector/home/security-graph.svg"
import TooltipIcon from "vector/icons/small-tooltip-icon.svg"
import { useIsMobile } from "helpers/hooks/mediaQueryHook"
import styles from "./SecuritySection.module.css"

export const SecuritySection = () => {
  const isMobile = useIsMobile()
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Protected by the highest security standards</h2>
      <div className={styles.subtitleContainer}>
        <span className={styles.subItem}>
          <CheckIcon />
          Registered with the SEC
          <Tooltip
            placement="top"
            overlayInnerStyle={{ backgroundColor: "white" }}
            overlay={
              <div className={styles.tooltipContainer}>
                <div className={styles.tooltipTitle}>What this means</div>
                <div className={styles.tooltipText}>
                  Registration does not imply a certain level of skill or training nor does it imply endorsement by the
                  SEC.
                </div>
              </div>
            }
          >
            <span>
              <TooltipIcon className={styles.tooltipIcon} />
            </span>
          </Tooltip>
        </span>
        <span className={styles.subItem}>
          <CheckIcon />
          Certified SOC 2 Type 2
        </span>
        <span className={styles.subItem}>
          <CheckIcon />
          Assets insured by SIPC
          <Tooltip
            placement="top"
            overlayInnerStyle={{ backgroundColor: "white" }}
            overlay={
              <div className={styles.tooltipContainer}>
                <div className={styles.tooltipTitle}>What this means</div>
                <div className={styles.tooltipText}>
                  Securities held at Apex Clearing are insured by SIPC. SIPC does not protect against investment losses.{" "}
                  <Link href="https://www.sipc.org/for-investors/what-sipc-protects" target="_blank">
                    Learn what SIPC covers
                  </Link>
                </div>
              </div>
            }
          >
            <span>
              <TooltipIcon className={styles.tooltipIcon} />
            </span>
          </Tooltip>
        </span>
      </div>
      <div className={styles.graphContainer}>
        <SecurityIcon className={clsx(isMobile ? styles.securityGraphMobile : styles.securityGraph)} />
      </div>
    </div>
  )
}
